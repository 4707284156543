// status.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  private ATPU_StepValueSubject = new BehaviorSubject<number>(0);
  private userLevelSubject = new BehaviorSubject<string>("");


  constructor() { }


  // Get the current ATPU_Step
  getATPU_StepValue() {
    return this.ATPU_StepValueSubject.asObservable();
  }

  // Update the ATPU_Step value
  updateATPU_StepValue(count: number) {
    this.ATPU_StepValueSubject.next(count);
  }



  // Get the current user level
  getUserLevel() {
    return this.userLevelSubject.asObservable();
  }

  // Update the user level
  updateUserLevel(status: string) {
    this.userLevelSubject.next(status);
  }

}

