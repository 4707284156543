import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  handleError(error: any) {
    if (error.status == 401) {
      console.log("GlobalErrorHandlerService")
      var oldPath = window.location.pathname;
      //window.location.href = '/login?RedirectTo=' + oldPath;
    }
    else if (error instanceof HttpErrorResponse) {
    }
    else {
      console.error("error ", error)
    }
  }
} 
