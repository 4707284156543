import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { formatDate } from "@angular/common";

import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Injectable()

//@Component({
//    providers: [HttpClientService]
//})

export class CommonService {
  currentDate = new Date();
  deviceInfo: any;

  constructor(private router: Router, private route: ActivatedRoute, private deviceService: DeviceDetectorService
    , private sanitizer: DomSanitizer, private http: HttpClient) {
    this.getDeviceDetails();
  }

  getDeviceDetails() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    //this.deviceInfo = this.deviceService.getDeviceInfo();
    //const isMobile = this.deviceService.isMobile();
    //const isTablet = this.deviceService.isTablet();
    //const isDesktopDevice = this.deviceService.isDesktop();
  };

  parseDate(passed_date: any) {
    var updatedDate = new Date(passed_date)
    if (isNaN(updatedDate.getDay())) {
      //replace(/-/g, '/')
      updatedDate = new Date(passed_date.toString().replace('/', '-'));
    }
    return updatedDate;
  }


  getFormatedDateTest(passed_date: any) {
    if (passed_date.toString().includes("-")) {
      passed_date = passed_date.toString().replace(/-/g, "/");
    }
    var updatedDate = new Date(passed_date)
    if (isNaN(updatedDate.getDay())) {
      //replace(/-/g, '/')
      updatedDate = new Date(passed_date.toString().replace('/', '-'));
    }
    var entered_date = updatedDate;
    var month = entered_date.getMonth() + 1;
    var date = entered_date.getDate();
    var year = entered_date.getFullYear();
    return (month <= 9 ? '0' : '') + month + "/" + (date <= 9 ? '0' : '') + date + "/" + year;
  }

  getFormatedDate(passed_date: any) {
    try {


      // if (this.deviceInfo.device.toLowerCase() == "ipad" || this.deviceInfo.device.toLowerCase() == "iphone") {
      if (passed_date) {
        const format = 'MM/dd/yyyy';
        const myDate = passed_date;
        const locale = 'en-US';
        if (myDate != 'Invalid Date') {
          //console.log("myDate", myDate)
          const formattedDate = formatDate(myDate, format, locale);
          return formattedDate;
        }
      }
      return "";
    }
    catch (e) {
      //console.error(e)
      return "";
    }
    //}
    //else {
    //    var entered_date = this.parseDate(passed_date);
    //    var month = entered_date.getMonth() + 1;
    //    var date = entered_date.getDate();
    //    var year = entered_date.getFullYear();
    //    return (month <= 9 ? '0' : '') + month + "/" + (date <= 9 ? '0' : '') + date + "/" + year;
    //}
  }

  getFormatedDateWithDot(passed_date: any) {//Replaced with '/' as per wesleys new recommendations.
    var entered_date = this.parseDate(passed_date);
    var month = entered_date.getMonth() + 1;
    var date = entered_date.getDate();
    var year = entered_date.getFullYear();
    return (month <= 9 ? '0' : '') + month + "/" + (date <= 9 ? '0' : '') + date + "/" + year;
  }

  getWeekEndDate(passed_date: any) {
    if (passed_date.toString().includes("-")) {
      passed_date = passed_date.toString().replace(/-/g, "/");
    }

    var Days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var days_weekend = [];
    var WeekStartDayID = localStorage.getItem('SelectedBusinessWeekStartDayID');
    if (parseInt(WeekStartDayID) == 4) {
      days_weekend = ['wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'monday', 'tuesday'];
    }
    else if (parseInt(WeekStartDayID) == 1) {
      days_weekend = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    }
    else if (parseInt(WeekStartDayID) == 2) {
      days_weekend = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    }
    else if (parseInt(WeekStartDayID) == 3) {
      days_weekend = ['tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'monday'];
    }
    else if (parseInt(WeekStartDayID) == 5) {
      days_weekend = ['thursday', 'friday', 'saturday', 'sunday', 'monday', 'tuesday', 'wednesday'];
    }
    else if (parseInt(WeekStartDayID) == 6) {
      days_weekend = ['friday', 'saturday', 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday'];
    }
    else if (parseInt(WeekStartDayID) == 7) {
      days_weekend = ['saturday', 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
    }

    var today_date = this.parseDate(passed_date);
    var day_name = Days[today_date.getDay()].toString();
    var dayofweek = 0;
    for (var i = 0; i < days_weekend.length; i++) {
      if (days_weekend[i].toLowerCase() == day_name.toLowerCase()) {
        dayofweek = i + 1;
        break;
      }
    }
    var startdate = this.parseDate(passed_date);
    startdate.setDate(today_date.getDate() + (dayofweek * -1));
    var week_ending = this.AddDaysInDate(startdate, 7);
    return week_ending;

    //ADD DAYS
    //today_date.setDate(today_date.getDate() + 4);
    //alert(today_date);

    //SUBTRACT DAYS
    //today_date.setDate(today_date.getDate() - 2);
  }

  AddDaysInDate(passed_date: any, days: number) {
    const format = 'MM/dd/yyyy';
    const myDate = passed_date;
    const locale = 'en-US';
    if (myDate != 'Invalid Date') {
      const formattedDate = formatDate(myDate, format, locale);
      var date = new Date(formattedDate);
      var date1 = date.setDate(date.getDate() + days);

      return formatDate(date1, format, locale);;
    }
    return "";

    //if (passed_date && passed_date.toString().includes("/")) {
    //    passed_date = passed_date.toString().replace(/-/g, "/");
    //}
    //alert("passed_date:" + passed_date);
    //if (this.deviceInfo.device.toLowerCase() == "ipad" || this.deviceInfo.device.toLowerCase() == "iphone") {
    //    passed_date = passed_date.toString().replace(/-/g, "/");
    //}
    //alert("passed_date1 : " + passed_date);
    //var today_date = this.parseDate(passed_date);
    //alert("today_date:" + today_date);
    //var d = today_date.setDate(today_date.getDate() + days);
    //alert("d:" + d);
    //return this.getFormatedDate(d).toString();
  }

  GetDayName(passed_date: any, dayStyle: string) {
    if (passed_date) {
      var d = this.parseDate(passed_date);
      if (dayStyle == "short") {
        var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return days[d.getDay()].toString();
      }
      else {
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return days[d.getDay()].toString();
      }
    }
    return '';
  }

  GetMonthName(passed_date: any, monthStyle: string) {
    if (passed_date) {
      var d = this.parseDate(passed_date);
      if (monthStyle == "short") {
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return months[d.getMonth()].toString();
      }
      else {
        var months = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"];
        return months[d.getMonth()].toString();
      }
    }
    return '';
  }

  GetMonthFromID(monthID, monthStyle: string) {
    if (monthID && monthID > -1) {
      var months = [];
      if (monthStyle == "short") {
        months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      }
      else {
        months = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"];
      }

      return months[(monthID - 1)].toString();
    }
    else {
      return 'NA';
    }
  }

  GetDayID(passed_date: any) {
    var d = this.parseDate(passed_date);
    return d.getDay() + 1;
  }

  getFormattedTime(time: string) {
    if (time != null && time != "" && time) {
      var splittedTime = time.split(':')
      if (splittedTime.length > 2) {
        return splittedTime[0] + ":" + splittedTime[1];
      }
      return time;
    }
    return "";
  }

  getTimeDifference(inTime, outTime) {
    if (inTime != null && inTime != "" && outTime != null && outTime != "") {
      var timeIn = this.parseDate("Wed, 27 July 2016 " + inTime);
      var timeOut = this.parseDate("Wed, 27 July 2016 " + outTime);


      if (timeOut > timeIn) {
        var diff = (timeOut.getTime() - timeIn.getTime()) / 1000;
        diff /= 60;
        var float = parseFloat((diff / 60).toFixed(2))
        return float.toString();
      }
      else if (timeIn > timeOut) {
        var timeOutNew = this.parseDate("Wed, 28 July 2016 " + outTime);
        var diff = (timeOutNew.getTime() - timeIn.getTime()) / 1000;
        diff /= 60;
        var float = parseFloat((diff / 60).toFixed(2))
        return float.toString();
      }
    }
    return "";
  }


  getTimeDifferenceWithDate(inTime, outTime, inDate, outDate) {
    if (inTime != null && inTime != "" && outTime != null && outTime != "" && inDate && outDate) {
      var newInDate = new Date(inDate)
      var newOutDate = new Date(outDate)

      newInDate.setHours(inTime.split(':')[0])
      newInDate.setMinutes(inTime.split(':')[1])

      newOutDate.setHours(outTime.split(':')[0])
      newOutDate.setMinutes(outTime.split(':')[1])

      var diff = (newOutDate.getTime() - newInDate.getTime()) / 1000;
      diff /= 60;
      var float = parseFloat((diff / 60).toFixed(2))
      return float.toString();
    }
    return "";
  }

  getExperience(dateOfHire: any) {
    if (dateOfHire && dateOfHire != '' && dateOfHire != null) {
      dateOfHire = this.parseDate(dateOfHire);

      if (dateOfHire.getFullYear() > 1970) {
        var dateDifference = Math.floor(this.currentDate.getTime() - (dateOfHire.getTime()));
        var day = 1000 * 60 * 60 * 24;

        var days = Math.floor(dateDifference / day);
        var months = Math.floor(days / 31);


        if (months < 0 && days > 0) {
          return days + "D";
        }
        else if (months < 0 && days < 0) {
          return "NA";
        }
        else if (months < 12) {
          return months + "M";
        }
        else {
          var remainder = months % 12;
          var divident = months / 12;

          if (divident > 70) {
            return "NA";
          }
          else {
            if (remainder == 0) {
              return divident + "y";
            } else {
              return parseInt(divident.toString()) + "y " + remainder + "m";
            }
          }
        }
      }
      else {
        return "NA";
      }
    }
    else {
      return "NA";
    }
  }

  getExperienceTillDate(dateOfHire: any, LastDayOfWork) {
    var lastday = new Date(LastDayOfWork)
    if (dateOfHire && dateOfHire != '' && dateOfHire != null) {
      dateOfHire = this.parseDate(dateOfHire);

      if (dateOfHire.getFullYear() > 1970) {
        var dateDifference = Math.floor(lastday.getTime() - (dateOfHire.getTime()));
        var day = 1000 * 60 * 60 * 24;

        var days = Math.floor(dateDifference / day);
        var months = Math.floor(days / 31);
        if (months < 0 && days > 0) {
          return days + "D";
        }
        else if (months < 0 && days < 0) {
          return "NA";
        }
        else if (months < 12) {
          return months + "M";
        }
        else {
          var remainder = months % 12;
          var divident = months / 12;
          if (divident > 70) {
            return "NA";
          }
          else {
            if (remainder == 0) {
              return divident + "YR";
            } else {
              return parseInt(divident.toString()) + "YR " + remainder + "M";
            }
          }
        }
      }
      else {
        return "NA";
      }
    }
    else {
      return "NA";
    }
  }

  getTotalDays(startDate, endDate) {
    var start = this.parseDate(startDate);
    var end = this.parseDate(endDate);

    var dateDifference = Math.floor(end.getTime() - (start.getTime()));
    var day = 1000 * 60 * 60 * 24;

    var days = Math.floor(dateDifference / day);
    return days + 1;  // 1 is added to add starting day.
  }

  getEmployeeNumberWithoutStore(empNumber: string) {
    if (empNumber && empNumber != '' && empNumber != null) {
      var numberArray = empNumber.split('-')
      return numberArray[(numberArray.length - 1)].toString();
      // return numberArray[1].toString();
    }
    return "";
  }

  FilterViewStatus() {
    return {
      set: function (data) {
        localStorage.setItem('FilterStatus', data);
      },
      get: function () {
        var data = localStorage.getItem('FilterStatus');
        if (data == null || data == "false") {
          return false;
        }
        else {
          return true;
        }
      }
    }
  }

  FilterOptionsService() {
    return {
      set: function (data) {
        localStorage.removeItem('FilterOptions');
        localStorage.setItem('FilterOptions', JSON.stringify(data));
      },
      get: function () {
        var data = localStorage.getItem('FilterOptions');
        return data;
      }
    }
  }

  UserStatusTypeService() {
    return {
      set: function (data) {
        localStorage.setItem('UserStatus', JSON.stringify(data));
      },
      get: function () {
        var data = localStorage.getItem('UserStatus');
        return parseInt(data);
      }
    }
  }

  BusinessTypeService() {
    return {
      set: function (data) {
        localStorage.setItem('BusinessType', JSON.stringify(data));
      },
      get: function () {
        var data = localStorage.getItem('BusinessType');
        return parseInt(data);
      }
    }
  }

  NotificationService(Type) {
    return {
      set: function (data) {
        if (Type == "Anniversary") {
          localStorage.setItem('AnnieversaryLabel', JSON.stringify(data));
        }
        else if (Type == "Birthday") {
          localStorage.setItem('BirthdayLabel', JSON.stringify(data));
        }
        else if (Type == "Bell") {
          localStorage.setItem('BellLabel', JSON.stringify(data));
        }

      },
      get: function () {
        var data;
        if (Type == "Anniversary") {
          data = localStorage.getItem('AnnieversaryLabel');
        }
        else if (Type == "Birthday") {
          data = localStorage.getItem('BirthdayLabel');
        }
        else if (Type == "Bell") {
          data = localStorage.getItem('BellLabel');
        }
        return parseInt(data);
      }
    }
  }


  BusinessWeekStartDayIDService() {
    return {
      set: function (data) {
        localStorage.setItem('SelectedBusinessWeekStartDayID', JSON.stringify(parseInt(data)));
      },
      get: function () {
        var data = localStorage.getItem('SelectedBusinessWeekStartDayID');
        return parseInt(data);
        //return parseInt('1');
      }
    }
  }


  UserStoreGuidService() {
    return {
      set: function (data) {
        localStorage.setItem('UserStoreGuid', JSON.stringify(data));
      },
      get: function () {
        var data = JSON.parse(localStorage.getItem('UserStoreGuid'));
        return data;
      }
    }
  }

  PrivilegesService() {
    return {
      set: function (data) {
        localStorage.setItem('UserPrivileges', JSON.stringify(data));
      },
      get: function (url) {
        //alert(2);
        //console.log(url);
        var data = JSON.parse(localStorage.getItem('UserPrivileges'));

        if (url && data && data.length > 0) {
          data = data.filter(item => (item.FormGroupUrl == url || item.FormGroupName == url));
        }
        return data;
      }
    }
  }

  MenuService() {
    return {
      set: function (data) {
        localStorage.setItem('SubsourceMenu', JSON.stringify(data));
      },
      get: function () {
        var data = JSON.parse(localStorage.getItem('SubsourceMenu'));
        return data;
      }
    }
  }

  UserTypeService() {
    return {
      get: function () {
        var data = localStorage.getItem('UserInfo');
        var TypeId = JSON.parse(data).Login.TypeId;
        return parseInt(TypeId);
      }
    }
  }

  getAge(dateOfBirth: any) {
    dateOfBirth = this.parseDate(dateOfBirth);
    var years = Math.floor((this.currentDate.getTime() - (dateOfBirth.getTime())) / (365 * 24 * 60 * 60 * 1000));
    if (years < 18) {
      return true;
    }
    else {
      return false;
    }
  }

  /******************************** Time Conversion Method ************************************/
  convertTo24Hour(time) {
    time = time.toString();
    var split_time = time.split(":");
    var hours = parseInt(split_time[0]);
    var minutes = parseInt(split_time[1]);

    if ((time.indexOf('AM') != -1 && hours == 12) || (time.indexOf('A') != -1 && hours == 12)
      || (time.indexOf('a') != -1 && hours == 12) || (time.indexOf('am') != -1 && hours == 12)) {
      time = 0 + ":" + minutes;
    }
    else if ((time.indexOf('AM') != -1 && hours < 12) || (time.indexOf('A') != -1 && hours < 12)
      || (time.indexOf('am') != -1 && hours < 12) || (time.indexOf('a') != -1 && hours < 12)) {
      time = hours + ":" + minutes;
    }
    else if ((time.indexOf('PM') != -1 && hours < 12) || (time.indexOf('P') != -1 && hours < 12)
      || (time.indexOf('pm') != -1 && hours < 12) || (time.indexOf('p') != -1 && hours < 12)) {
      hours = hours + 12;
      time = hours + ":" + minutes;
    }
    var return_time = time.replace(/(am|pm|PM|AM|a|p|A|P)/, '');
    return return_time;
  }

  convertTo12Hour(time) {
    time = time.toString();
    var split_time = time.split(":");
    var hours = parseInt(split_time[0]);
    var minutes = split_time[1];
    if (minutes.length != 2) {
      minutes = minutes + "0";
    }
    var new_time = '';
    if (hours == 24) {
      new_time = "12" + ':' + minutes + "AM";
    }
    else if (hours < 12) {
      if (hours == 0) {
        new_time = 12 + ':' + minutes + "AM";
      } else {
        new_time = hours + ':' + minutes + "AM";
      }
    }
    else if (hours > 12) {
      var new_hours = hours - 12;
      new_time = new_hours + ':' + minutes + "PM";
    }
    else if (hours == 12) {
      new_time = "12" + ':' + minutes + "PM";
    }
    var return_time = new_time
    return return_time;
  }

  convertTo12HourSmall(time) {
    time = time.toString();
    var split_time = time.split(":");
    var hours = parseInt(split_time[0]);
    var minutes = split_time[1];
    if (minutes.length != 2) {
      minutes = minutes + "0";
    }
    var new_time = '';
    if (hours == 24) {
      new_time = "12" + ':' + minutes + "a";
    }
    else if (hours < 12) {
      if (hours == 0) {
        new_time = 12 + ':' + minutes + "a";
      } else {
        new_time = hours + ':' + minutes + "a";
      }
    }
    else if (hours > 12) {
      var new_hours = hours - 12;
      new_time = new_hours + ':' + minutes + "p";
    }
    else if (hours == 12) {
      new_time = "12" + ':' + minutes + "p";
    }
    var return_time = new_time
    return return_time;
  }

  convertTo12HourFullfromSmall(time) {
    time = time.toString();
    time = time.replace('a', 'AM')
    time = time.replace('p', 'PM')
    return time;
  }


  convertTo12HourSmallfromFull(time) {
    time = time.toString();
    time = time.replace('AM', 'a')
    time = time.replace('PM', 'p')
    return time;
  }

  getRandomName(length) {
    var result = '';
    var characters = 'abcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  RemoveReadOnly(id) {
    document.getElementById(id).removeAttribute("readonly");
  }

  AddReadOnly(id) {
    document.getElementById(id).setAttribute("readonly", "readonly")
  }

  GetFormatedBodyWithStyle(content) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  checkCourses(course) {
    var showUos2 = false;
    if (course) {
      var re = /Sandwich Artistry 2/gi;
      var str = course;
      if (str.search(re) == -1) {
        showUos2 = false;
      }
      else {
        showUos2 = true;
      }
    }
    return showUos2;
  }

  getIPAddress() {
    return this.http.get("http://api.ipify.org/?format=json");
  }


  FormattedTimeDiff(MiliSeconds) {
    // get total days between two dates
    var res = MiliSeconds / 1000;

    //var days = Math.floor(res / 86400);
    //document.write("<br>Difference (Days): " + days);

    // get hours        
    var hours = Math.floor(res / 3600) % 24;
    //console.log("<br>Difference (Hours): " + hours);

    // get minutes
    var minutes = Math.floor(res / 60) % 60;
    //console.log("<br>Difference (Minutes): " + minutes);

    // get seconds
    var seconds = Math.round(res % 60);
    //console.log("<br>Difference (Seconds): " + seconds);

    return (hours > 0 ? (hours.toString() + "h ") : "") + (minutes > 0 || hours > 0 ? (minutes.toString() + "m ") : "") + (minutes > 0 || hours > 0 || seconds > 0 ? (seconds.toString() + "s") : "")
  }


  getSetATPUPageID() {
    return {
      set: function (data) {
        localStorage.setItem('ATPUPageID', data);
      },
      get: function () {
        var data = localStorage.getItem('ATPUPageID');
        if (data) {
          return data;
        }
        else {
          return "0";
        }
      }
    }
  }



}


