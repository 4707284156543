import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from "../services/common.service";
import { BackEndUrlService } from '../services/backend.url.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';

import { Inject } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';

import { AppComponent } from "../../app.component"
import { HttpHeaders, HttpClient } from '@angular/common/http';


@Component({
  providers: [CommonService, BackEndUrlService],
  templateUrl: "./login.component.html",
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  showError: boolean = false;
  Submitted: boolean = false;
  redirectUrl: string = "";
  ErrorMessage: string = "";
  date = new Date();
  CurrentYear = this.date.getFullYear();
  @ViewChild('inputEmail') inputEmail: ElementRef;

  ShowSetupStep: boolean = false;
  LoggedInUser: string = "";
  CompletionPercent: number = 0;

  constructor(private formBuilder: FormBuilder, public Common: CommonService, private cookieService: CookieService,
    private titleService: Title, private router: Router, public _main: AppComponent, private _backEndUrlService: BackEndUrlService, @Inject(DOCUMENT) document,
    private httpCl: HttpClient, private locationVar: Location) {

  }

  apiUrl: string = this._backEndUrlService.backendUrl;

  ngOnInit() {
    this.redirectUrl = "";
    this.titleService.setTitle('Login | The Only Awareness Community');
    this.loginForm = this.formBuilder.group({
      UserName: ['', Validators.required],
      Password: ['', Validators.required],
    });

    //this.inputEmail.nativeElement.focus();

    var url = window.location.search;
    var path = url.slice(url.indexOf('?') + 1);
    var arr_content = path.split('=');
    var returned_string = arr_content[0];

    if (returned_string == "RedirectTo") {
      this.redirectUrl = decodeURIComponent(arr_content[1]);

    }

    localStorage.removeItem("UserInfo");
    localStorage.removeItem("IsATPU_StepsCompleted");
    this.cookieService.deleteAll();

    //console.log("this.redirectUrl", this.redirectUrl)
  }

  //ngAfterViewInit() {
  //    this.inputEmail.nativeElement.focus();
  //}

  //*******[START]****************************** Login *****************************************

  RemoveSpaces() {
    var NewUserName = this.loginForm.value.UserName.replace(/\s/g, "");
    this.loginForm.controls['UserName'].setValue(NewUserName);
    //console.log(this.loginForm.value.UserName);
  }

  Login() {
    this.showError = false;
    this.ErrorMessage = "";
    this.cookieService.deleteAll();
    localStorage.clear();

    if (this.loginForm.valid) {
      this.Submitted = true;
      this.cookieService.deleteAll();

      let header = new HttpHeaders();
      //let other_header = header.append('Authorization', 'Bearer ' + this.getCookie("authorizetoken111"));
      var options = {
        //headers: other_header
      };
      var url = this.apiUrl + "ValidateLoginAttempt";

      var regExp = /&/gi;
      var strPassword = this.loginForm.value.Password;
      var newPassword = "";

      if (strPassword.search(regExp) == -1) {
        newPassword = this.loginForm.value.Password;

      } else {
        newPassword = strPassword.replace(regExp, "%26");
      }

      var UserName = this.loginForm.value.UserName.replace(/\s/g, "");
      //console.log(UserName);


      var data = "grant_type=password&username=" + UserName + "&password=" + newPassword + "&module=admin";
      console.log(data)

      var observable = this.httpCl.post(url, data, options);
      observable.subscribe(
        (res: any) => {
          console.log(res, 'response')
          if (JSON.parse(res.LoginObject).Status > 0) {
            this.cookieService.deleteAll();
            this.cookieService.set("authorizetoken111", res.access_token, 0.60);
            localStorage.setItem("UserInfo", res.LoginObject);

            this.Common.UserStatusTypeService().set(JSON.parse(res.LoginObject).Login.StatusID);
            this.Common.getSetATPUPageID().set(JSON.parse(res.LoginObject).Login.ATPUPageID);
            if (JSON.parse(res.LoginObject).Login.ATPUPageID == 10) {
              window.location.href = "/account/dashboard";
            //this.router.navigate(['/account', 'dashboard']);
            }
            else {
              window.location.href = "/account/atpu-steps";
            }
           
          }
          else {
            this.ErrorMessage = "Invalid Username/Password";
            this.showError = true;
            this.Submitted = false;
          }
        },
        (err: any) => {
          console.log(err)
          if (err.error.error_description) {
            this.ErrorMessage = err.error.error_description;
          }
          else {
            this.ErrorMessage = "Invalid Username/Password";
          }
          this.showError = true;
          this.Submitted = false;
        }
      );
    }
    else {
      this.ErrorMessage = "Invalid Username/Password";
      this.showError = true;
      this.Submitted = false;
    }
  }

  CurrentStep: number = 1;
  PageSetupID: number = 1;


  checkLoginModule(compareWith, passedUrl) {
    var valideUrl = true;
    if (compareWith && passedUrl) {
      var re = /admin/gi;
      if (compareWith = "admin") {
        re = /admin/gi;
      }
      else if (compareWith = "employee") {
        re = /employee/gi;
      }
      else if (compareWith = "developer") {
        re = /developer/gi;
      }

      var str = passedUrl;
      if (str.search(re) == -1) {
        valideUrl = false;
      }
      else {
        valideUrl = true;
      }
    }
    return valideUrl;
  }

  ContinueLoginProcess(completeSetup) {
    if (completeSetup) {
      if (this.PageSetupID == 1) {
        this.router.navigate(['/admin', 'company-setup']);
      }
      else if (this.PageSetupID == 2) {
        this.router.navigate(['/admin', 'employee-module-setup']);
      }
      else if (this.PageSetupID == 3) {
        this.router.navigate(['/admin', 'hiring-setup']);
      }
    }
    else {
      this.router.navigate(['/admin', 'dashboard']);
    }

  }

  public LogoutUser() {
    this.cookieService.deleteAll();
    this.cookieService.delete('authorizetoken111', '/');

    localStorage.clear();
    this.locationVar.replaceState('/'); // clears browser history so they can't navigate with back button
    window.location.href = "/";
    //this.router.navigate(['/']);
  }

}
