import { Injectable } from '@angular/core';
//import { Component } from "@angular/core"
import { Http, Headers, RequestOptions, RequestMethod } from '@angular/http';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BackEndUrlService } from './backend.url.service';

//const httpOptions = {
//    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
//};

@Injectable()

//@Component({
//    providers: [CookieService, BackEndUrlService]
//})

export class HttpClientService {
  public loading = true;

  constructor(private http: Http, private _cookieService: CookieService, private _backEndUrlService: BackEndUrlService, private httpCl: HttpClient) { }

  backendUrlService: string = this._backEndUrlService.backendUrl;

  validateUserType() {
    var accesedPath = window.location.pathname;
    let userType = localStorage.getItem("UserType");
    if (accesedPath.includes("developer") || accesedPath.includes("admin") || accesedPath.includes("employee")) {
      if (accesedPath != "/login" && accesedPath != "/Login" && accesedPath != "/ForgetPassword" && accesedPath != "/ResetPassword"
        && accesedPath != "/signup" && accesedPath != "/unsubscribe" && accesedPath != "/employee-activation" && accesedPath != "/employee-registration" && !accesedPath.includes(userType)) {
         window.location.href = '/login?Reason=WrongModule';
      }
    }
  }

  getCookie(key: string) {
    this.validateUserType();
    return this._cookieService.get(key);
  }

  createAuthorizationHeader(headers: Headers) {
    if (this.getCookie("authorizetoken111") != "") {
      headers.append('Authorization', 'Bearer ' +
        this.getCookie("authorizetoken111"));
    }
    return headers;
  }

  getwithparam(url: any, data: any) {
    let header = new HttpHeaders();
    let other_header = header.append('Authorization', 'Bearer ' + this.getCookie("authorizetoken111"));
    var options = {
      headers: other_header
    };
    return this.httpCl.get(this.backendUrlService + url + "?" + data, options);
  }

  get(url: any) {
    let header = new HttpHeaders();
    let other_header = header.append('Authorization', 'Bearer ' + this.getCookie("authorizetoken111"));
    var options = {
      headers: other_header
    };
    return this.httpCl.get(this.backendUrlService + url, options);
  }

  post(url: any, data: any) {
    let header = new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + this.getCookie("authorizetoken111")
    });
    //header.append('Accept', 'application/json');
    //header.append('Authorization', 'Bearer ' + this.getCookie("authorizetoken111"));
    let other_header = header; 
    
    var options = {
      headers: other_header
    };
    return this.httpCl.post(this.backendUrlService + url, data, options);
  }

  //using old HTTP

  //getwithparam(url: any, data: any) {
  //  let headers = new Headers();
  //  this.createAuthorizationHeader(headers);
  //  let requestOptions = new RequestOptions();
  //  requestOptions.search = data;
  //  requestOptions.headers = headers;
  //  return this.http.get(this.backendUrlService + url, requestOptions);
  //}

  //get(url: any) {
  //  let headers = new Headers();
  //  headers = this.createAuthorizationHeader(headers);
  //  return this.http.get(this.backendUrlService + url, {
  //    headers: headers
  //  });
  //}

  //post(url: any, data: any) {
  //  let headers = new Headers();
  //  headers = this.createAuthorizationHeader(headers);
  //  return this.http.post(this.backendUrlService + url, data, {
  //    headers: headers
  //  });
  //}
}
