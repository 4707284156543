import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthGuardService {

  constructor(private authentication: AuthenticationService) { }

  canActivate(): boolean | Promise<boolean> {
    let token = this.authentication.getAccessToken();
    console.log("token",token)
    if (!token) {
      this.redirectToLoginPage();
      return false;
    }
    else if (this.authentication.isAuthenticated()) {
      //console.log("this.authentication.isAuthenticated", this.authentication.isAuthenticated)
      return true;
    }
    else {
      this.redirectToLoginPage()
      return false;
    }
  }

  redirectToLoginPage() {
    //var oldPath = window.location.pathname;
    localStorage.removeItem("UserInfo");
    window.location.href = '/login';//?RedirectTo=' + oldPath 
    //this.router.navigate(['/login']);
  }

}
